/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
// body { margin: 0; font-family: WhiteneyFont-Medium, "Helvetica Neue", sans-serif; background-color: unset }
body {
  margin: 0;
  font-family: WhiteneyFont-Medium, sans-serif;
  background-color: unset;
}

/******* Carousel *******/

.mat-checkbox-inner-container {
  display: inline-block;
  height: 20px;
  height: 20px;
  line-height: 0;
  margin: auto;
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 20px;
  flex-shrink: 0;
}

.checkbox--large .mat-checkbox-inner-container {
  border-radius: 50%;
  border: 1px solid #9e9e9e;
  border-top-color: rgb(158, 158, 158);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(158, 158, 158);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(158, 158, 158);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(158, 158, 158);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.mat-checkbox-inner-container {
  display: inline-block;
  height: 20px;
  line-height: 0;
  margin: auto;
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 20px;
  flex-shrink: 0;
}

.mat-checkbox-layout {
  cursor: inherit;
  align-items: baseline;
  vertical-align: middle;
  display: inline-flex;
  white-space: nowrap;
}

.mat-checkbox {
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.mat-checkbox-layout {
  cursor: inherit;
  align-items: baseline;
  vertical-align: middle;
  display: inline-flex;
  white-space: nowrap;
}

/* .mat-step-icon-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
} */

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  height: 15px;
  width: 15px;
  margin: 5px;
  background-color: rgb(221, 221, 221) !important;
  border-radius: 50%;
  display: inline-block;
  text-indent: -999px;
  cursor: pointer;
  background-clip: padding-box;
  border-top: 0px solid transparent !important;
  border-bottom: 0px solid transparent !important;
  transition: opacity 0.6s ease;
  padding: unset;
}

.carousel-indicators .active {
  background-color: #ff3a30 !important;
  opacity: 1;
}

.carousel-indicators {
  /*top: 32em !important;*/
  transform: translateY(50px);
}
:focus {
  outline: none !important;
}

.carousel-control-prev {
  padding-top: 190px;
}

.carousel-control-next {
  padding-top: 190px;
}

.check-heading {
  margin-bottom: unset !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

/******* Slider *******/

.mat-slider-thumb,
.mat-primary .mat-slider-thumb-label,
.mat-primary .mat-slider-track-fill .mat-slider-ticks-container .mat-accent .mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: #0056eb !important;
}

// #hsc-0 /deep/ .mat-accent .mat-slider-thumb {
//   background:  #F9F9F9 !important;
// }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #9cbdf3;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #0056eb;
}

/******* Dropdown *******/

.mat-select-panel {
  background: white;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

/******* Calendar *******/

/*.mat-calendar-table {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}*/

/******* Gray Block *******/
.gray-info-block {
  border-radius: 10px;
  background-color: #f9f9f9;
  border: 1px solid #979797;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 1.64286em;
  padding-bottom: 1.64286em;
}

button.mat-focus-indicator.mat-mini-fab.mat-button-base.mat-primary.ng-star-inserted {
  background-color: #bcbcbc !important;
  height: 18px;
  width: 18px;
  box-shadow: unset;
}

button.mat-focus-indicator.mat-mini-fab.mat-button-base.mat-primary.mat-button-disabled.ng-star-inserted {
  background-color: #e11b21 !important;
  opacity: 1;
  height: 18px;
  width: 18px;
  box-shadow: unset;
}

.carousel-indicators.ng-star-inserted {
  left: 51% !important;
}

.mat-form-field-flex {
  padding: 0px !important;
}
button.mat-focus-indicator.mat-icon-button.mat-button-base.mat-primary.ng-star-inserted {
  right: 0px !important;
}

span.mat-checkbox-label {
  min-width: 5em;
}

.mat-form-field-subscript-wrapper.ng-tns-c87-3 {
  padding: unset !important;
}

.mat-form-field-subscript-wrapper.ng-tns-c87-6 {
  padding: unset !important;
}
@media screen and (max-width: 960px) {
  .carousel {
    height: 30em !important;
  }
}

.mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color.ng-star-inserted {
  color: gray;
}

.mat-form-field-subscript-wrapper.ng-tns-c87-3 {
  padding: unset !important;
}

.mat-form-field-subscript-wrapper.ng-tns-c88-5 {
  padding: unset !important;
}

.ng-tns-c88-8.ng-trigger.ng-trigger-transitionMessages.ng-star-inserted {
  padding: unset !important;
}

/***** Check Styling Color *****/

.mat-form-field-invalid .mat-radio-label-content {
  color: #f44336;
}
.mat-form-field-invalid .mat-radio-outer-circle {
  border-color: #f44336 !important;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #1c4f94;
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1c4f94;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: #1c4f94;
}

.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  float: right;
  background-color: green;
  /* outline: 4px groove green; */
  color: rgb(238, 237, 237);
  text-decoration: underline wavy #88ff88;
  text-shadow: 2px 2px 3px #fcfbfb;
}

.mat-step-icon-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* background-color: white;  */
}

.mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color.ng-star-inserted {
  color: white;
}

th.mat-header-cell {
  text-align: center !important;
}

/* Styles available for grid questions */

@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material-mixin';

/*Header Alignment*/
/*text-center*/
.ag-header-cell.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}

/*text-left*/
.ag-header-cell.text-left {
  .ag-header-cell-label {
    justify-content: left;
  }
}

/*text-right*/
.ag-header-cell.text-right {
  .ag-header-cell-label {
    justify-content: right;
  }
}

/*Header Coloring*/
/* lightgray */
.grid-header-lightgray {
  background-color: lightgray;
}

/* if you want to highlight the editing row, you can make use of the ag-row-editing class, it gets put onto
       the row that is currently in edit mode. */
.ag-row-editing {
  background-color: #5bc0de !important;
}

/*Header and cell Borders*/
.ag-theme-material {
  @include ag-theme-material(
    (
      // use theme parameters where possible
      // Default border for cells. This can be used to specify the border-style and border-color properties e.g. `dashed red` but the border-width is fixed at 1px.
      cell-horizontal-border: solid ag-derived(secondary-border-color)
    )
  );
  .ag-header-cell {
    // or write CSS selectors to make customisations beyond what the parameters support
    border-right: 1px solid ag-param(secondary-border-color);
  }
}
